import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

const settlementTypes = ['as', 'as']
const currencyCodes = ['as', 'as']
const bankSettlementTypes = ['as', 'as']
const payeeBankSettlTypes = ['']

export default {
  namespaced: true,
  state: {
    bnf: {
      paySetPayBankDefId: {
        display: false,
        value: 0,
        type: 'select',
      },
      currencyId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'currency',
        options: currencyCodes,
      },
      settlementTypeId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'settlementForm',
        options: settlementTypes,
      },
      bankSettlementTypeId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'bank',
        options: bankSettlementTypes,
      },
      payeeBankSettlTypeId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'beneficiary',
        options: payeeBankSettlTypes,
      },
      dateCreated: {
        display: false,
        value: new Date().toISOString(),
        type: 'input',
        label: 'dateCreated',
      },
      createdBy: {
        display: false,
        value: '',
        type: 'input',
        label: 'createdBy',
      },
      dateUpdated: {
        display: false,
        value: new Date().toISOString(),
        type: 'input',
        label: 'dateUpdated',
      },
      updatedBy: {
        display: false,
        value: '',
        type: 'input',
        label: 'updatedBy',
      },
      version: {
        display: false,
        value: '',
        type: 'input',
        label: 'version',
      },
    },
  },
  getters: {
    getterBnf(state) {
      return state.bnf
    },
  },
  mutations: {
    settingPayeeBankSettleId(state, optionsBankSettlementType) {
      const formattedData = optionsBankSettlementType.map(settlement => ({
        code: settlement.payeeSettlementId,
        value: settlement.payeeSettlementId,
        text: `${settlement.accountNumber} - ${settlement.bankName}`,
      }))
      state.bnf.payeeBankSettlTypeId.options = formattedData
    },
    changeSettlementTypeByCurrrencie(state, currencies) {
      const currentCurrencies = currencies.map(currencie => ({
        code: currencie.code,
        value: currencie.id,
        text: currencie.name,
      }))
      state.bnf.currencyId.settlementTypes = currentCurrencies
    },
  },
  actions: {
    async getRelationBnf(_,
      {
        filterByProductCode, orderBy = null, perPage, currentPage,
      }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/pay_set_bank_def?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: true,
              FilterByProductCode: filterByProductCode,
            }, // OrderBy[var]=ASC || DESC <-- Formato correcto
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveRelation(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pay_set_bank_def', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateRelation(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/pay_set_bank_def/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    searchRelation(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/pay_set_bank_def/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async fetchSettlementBank({ commit, state }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/payee-settlement-types/find-settlement-types', {
            params: {
              PayeeId: 1,
            },
          })
          .then(response => {
            commit('settingPayeeBankSettleId', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async fetchSettlementTypeByCurrency({ commit }, { currencie }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/settlement-types/get_settlements_by_currency', {
            params: {
              currencyId: currencie,
            },
          })
          .then(response => {
            commit('changeSettlementTypeByCurrrencie', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async fetchCurrencieTypes({ rootGetters }, { bnf = {} }) {
      const currencieOptions = await rootGetters['init/currencies'].map(currencie => ({
        value: currencie.id,
        text: currencie.name,
      }))
      bnf.value.currencyId.options = currencieOptions
    },
    async fetchSettlementForm({ rootGetters }, { bnf = {} }) {
      const settlementOptions = await rootGetters['init/settlementTypes'].map(settlement => ({
        code: settlement.code,
        value: settlement.id,
        text: settlement.name,
      }))
      bnf.value.settlementTypeId.options = settlementOptions
    },
    async fetchBankSettlementType({ rootGetters }, { bnf = {} }) {
      const bankSettlementType = await rootGetters['init/bankSettlementType'].map(bank => ({
        code: bank.code,
        value: bank.id,
        text: bank.name,
      }))
      bnf.value.bankSettlementTypeId.options = bankSettlementType
    },
  },
}
