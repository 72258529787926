import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
// import { sortRemote } from '@/helpers'
const countries = ['as', 'as']
const currencyCodes = ['as', 'as']
const valuations = ['as', 'as']

export default {
  namespaced: true,
  state: {
    currenciesData: {
      currencyId: {
        display: false,
        value: 0,
        type: 'numInput',
      },
      currencyISOCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'Código ISO',
      },
      currencyName: {
        display: true,
        value: '',
        type: 'input',
        label: 'Divisa',
      },
      countryID: {
        display: true,
        value: 0,
        type: 'select',
        label: 'Pais',
        options: countries,
      },
      valuationTypeCode: {
        display: true,
        value: 0,
        type: 'select',
        label: 'Tipo de valoración',
        options: valuations,
      },
      currencyTypeCode: {
        display: true,
        value: 0,
        type: 'select',
        label: 'Tipo de divisa',
        options: currencyCodes,
      },
      factor: {
        display: true,
        value: 0,
        type: 'numInput',
        label: 'Factor',
      },
      symbol: {
        display: true,
        value: '',
        type: 'input',
        label: 'Simbolo',
      },
      version: {
        display: false,
        value: '',
        type: 'input',
        label: 'version',
      },
      uifCode: {
        display: false,
        value: '-',
        type: 'input',
        label: 'codigo uif',
      },
      createdBy: {
        display: false,
        value: '',
        type: 'input',
        label: 'creadopor',
      },
      cnbvcode: {
        display: false,
        value: '',
        type: 'input',
        label: 'cnbv',
      },
      chargesTo: {
        display: true,
        value: '',
        type: 'input',
        label: 'Cargo a',
      },
      balanceInitial: {
        display: false,
        value: 0,
        type: 'numInput',
        label: 'balanceIninial',
      },
      nettingAccountIDStrong: {
        display: false,
        value: 0,
        type: 'numInput',
        label: 'Cuentas neteo divisas',
      },
      nettingAccountIDWeak: {
        display: false,
        value: 0,
        type: 'numInput',
        label: 'Cuenta neteo pesos',
      },
      currencyIsActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'Activo',
      },
    },
  },
  getters: {
    currenciesData(state) {
      return state.currenciesData
    },
  },
  mutations: {},
  actions: {
    async fetchCurrencies(
      _,
      {
        orderBy = null,
        perPage,
        currentPage,
      },
    ) {
      const response = await sigloNetAxiosInstance.get('/currencies',
        {
          params: {
            PerPage: perPage,
            CurrentPage: currentPage,
            orderBy,
          },
        })
      return response.data
    },
    async fetchCurrenciesById(_ ,{ id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/currencies/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async updateCurrency(_ ,{ id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/currencies/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveCurrency(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/currencies', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async fetchCountries({ rootGetters }, { currenciesData = {} }) {
      const countryOptions = await rootGetters['init/countries'].map(country => ({
        value: country.countryID,
        text: country.countryName,
      }))
      currenciesData.value.countryID.options = countryOptions
    },
    async fetchCurrencieTypes({ rootGetters }, { currenciesData = {} }) {
      const currencieOptions = await rootGetters['init/currenciesTypes'].map(currencie => ({
        value: currencie.id,
        text: currencie.name,
      }))
      currenciesData.value.currencyTypeCode.options = currencieOptions
    },
    async fetchValuationTypes({ rootGetters }, { currenciesData = {} }) {
      const valuationOptions = await rootGetters['init/valuation'].map(valuation => ({
        value: valuation.id,
        text: valuation.name,
      }))
      currenciesData.value.valuationTypeCode.options = valuationOptions
    },

    getSettlementType() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/currencies/get_by_settlement_type')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
