import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {
    news: {
      newsId: {
        display: false,
        value: 0,
        type: 'input',
      },
      createdBy: {
        display: false,
        value: '',
        type: 'input',
      },
      dateCreated: {
        display: false,
        value: new Date().toISOString(),
        type: 'input',
      },
      dateUpdated: {
        display: false,
        value: new Date().toISOString(),
        type: 'input',
      },
      newsHeadline: {
        display: true,
        value: '',
        type: 'input',
        label: 'newsHeadline',
        size: 12,
      },
      newsArticle: {
        display: true,
        value: '',
        type: 'textBox',
        typeInput: '',
        label: 'article',
        size: 12,
      },
      newsIsActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      updatedBy: {
        display: false,
        value: '',
        type: 'input',
      },
      version: {
        display: false,
        value: '',
        type: 'input',
      },
    },
  },
  getters: {
    getNewsData(state) {
      return state.news
    },
  },
  mutations: {},
  actions: {
    getNews(
      _,
      {
        orderBy = null, perPage, currentPage, fiterByHeadline,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/news?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FiterByHeadline: fiterByHeadline,
            }, // OrderBy[var]=ASC || DESC <-- Formato correcto
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getNewFromId(
      _,
      {
        id,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/news/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateNew(
      _,
      {
        data,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/news/${data.newsId}`, {
            ...data,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    uploadData(
      _,
      {
        data,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/news', {
            ...data,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
