import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: { personData: {} },
  getters: {
    personData(state) {
      return state.personData
    },
  },
  mutations: {
    SET_PERSON_DATA(state, val) {
      state.personData = val
    },
  },
  actions: {
    searchPersons(_, { PersonType, Nombre }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/persons/search_persons', {
            params: {
              PersonType,
              Nombre,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    savePerson(
      _,
      {
        personRelatedPersonId,
        personId,
        relatedPersonId,
        relationTypeId,
        isActive,
        version,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/persons/save_related_person', {
            personRelatedPersonId,
            personId,
            relatedPersonId,
            relationTypeId,
            isActive,
            version,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteRelatedPerson(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/persons/delete_related_person ', payload)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    savePersons(_, { payload }) {
      const form = new FormData()
      form.append('PersonID', payload.personID)
      form.append('RFC', payload.rfc)
      form.append('Name', payload.name)
      form.append('EconomicActivityTypeCode', payload.economicActivityTypeCode)
      form.append('CountryId', payload.countryId)
      form.append('IsLegalPerson', payload.isLegalPerson)
      if (payload.isForeignCitizen) {
        form.append('IsForeignCitizen', payload.isForeignCitizen)
      }
      form.append('Image', payload.image)
      form.append('ImageFile', payload.imageFile)
      form.append('BanxicoSectorTypeId', payload.banxicoSectorTypeId)
      form.append('StateId', payload.stateId)
      form.append('IsActive', payload.isActive)
      form.append('Version', payload.version)

      if (payload.naturalPersonFiscalInformation) {
        form.append('NaturalPersonFiscalInformation.NaturalPersonId ', payload.naturalPersonFiscalInformation.naturalPersonId)
        form.append('NaturalPersonFiscalInformation.PersonId', payload.naturalPersonFiscalInformation.personId)
        form.append('NaturalPersonFiscalInformation.PaternalSurname', payload.naturalPersonFiscalInformation.paternalSurname)
        form.append('NaturalPersonFiscalInformation.MaternalSurname', payload.naturalPersonFiscalInformation.maternalSurname)
        form.append('NaturalPersonFiscalInformation.Birthdate', payload.naturalPersonFiscalInformation.birthdate)
        form.append('NaturalPersonFiscalInformation.BirthCountryID', payload.naturalPersonFiscalInformation.birthCountryID)
        form.append('NaturalPersonFiscalInformation.IsActive', payload.naturalPersonFiscalInformation.isActive)
        form.append('NaturalPersonFiscalInformation.Version', payload.naturalPersonFiscalInformation.version)
      } else {
        form.append('LegalPersonFiscalInformation.LegalPersonId', payload.legalPersonFiscalInformation.legalPersonId)
        form.append('LegalPersonFiscalInformation.PersonId', payload.legalPersonFiscalInformation.personId)
        form.append('LegalPersonFiscalInformation.LegalPersonShortName', payload.legalPersonFiscalInformation.legalPersonShortName)
        form.append('LegalPersonFiscalInformation.FiscalBusinessName', payload.legalPersonFiscalInformation.fiscalBusinessName)
        form.append('LegalPersonFiscalInformation.EstablishmentDate', payload.legalPersonFiscalInformation.establishmentDate)
        form.append('LegalPersonFiscalInformation.WebPage', payload.legalPersonFiscalInformation.webPage)
        form.append('LegalPersonFiscalInformation.IsActive', payload.legalPersonFiscalInformation.isActive)
        form.append('LegalPersonFiscalInformation.Version', payload.legalPersonFiscalInformation.version)
      }

      form.append('AdditionalData', payload.additionalData)

      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/persons', form, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    searchPersonsV2(_, { PersonType, Nombre }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/persons/search_persons_v2', {
            params: {
              PersonType,
              Nombre,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getPersonById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/persons/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
