import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    operationTypes: {
      speiOperationTypeId: {
        display: false,
        value: 0,
      },
      speiOperationTypeName: {
        display: true,
        value: '',
        type: 'input',
        label: 'operationType',
      },
      speiOperationTypeCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'code',
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      version: {
        display: false,
        value: '',
      },
    },
  },
  getters: {
    operationTypes(state) {
      return state.operationTypes
    },
  },
  mutations: {},
  actions: {
    getOperationTypes() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('v1/spei_operation_types')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchOperationTypeById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/spei_operation_types/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveOperationType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/spei_operation_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateOperationType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/spei_operation_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
