import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    operationTypes: {
      spidOperationTypeId: {
        display: false,
        value: 0,
      },
      spidOperationTypeCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'code',
      },
      spidOperationTypeName: {
        display: true,
        value: '',
        type: 'input',
        label: 'description',
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      version: {
        display: false,
        value: '',
      },
    },
  },
  getters: {
    operationTypes(state) {
      return state.operationTypes
    },
  },
  mutations: {},
  actions: {
    getOperationTypes(
      _,
      {
        perPage, currentPage, filterByProductCode, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/spid_operation_types', {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: null,
              FilterByProductCode: filterByProductCode,
              OrderBy: orderBy,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchOperationTypeById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/spid_operation_types/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveOperationType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/spid_operation_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateOperationType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/spid_operation_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
