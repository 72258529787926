import axios from 'axios'
import { getEnvVariable } from '@/helpers'
import extendAxiosInstance from './baseAxiosInstance'

const consoleAxiosInstance = axios.create({
  baseURL: getEnvVariable('VUE_APP_API_URL_CONSOLE', process.env.VUE_APP_API_URL_CONSOLE),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: getEnvVariable('TIME_ABORT_REQUEST', process.env.TIME_ABORT_REQUEST),
})

extendAxiosInstance(consoleAxiosInstance)

export default consoleAxiosInstance
