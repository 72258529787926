import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {
    terms: [],
  },
  getters: {
    getTerms(state) {
      return state.terms
    },
  },
  mutations: {},
  actions: {
    getSuggestedDay() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day/get_suggested_day_to_start')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getSelectedDay(_, selectedDay) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day/select_day_to_start', {
            params: {
              selectedDay,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    confirmValuesDates(_, selectedDate) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/open_day/confirm_value_dates', selectedDate)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getForwardDays(_, { forwardDay }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/open_day/get_forward_days/${forwardDay}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getForwardDate(_, { forwardDate = '0' }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          // eslint-disable-next-line
          .get(`/open_day/get_forward_date/${forwardDate ? forwardDate : '1'}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getExecuteStartingDay() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day/execute_starting_day')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getOperationsOfDay(_, DateToProcess) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day/get_operations_of_day', {
            params: {
              DateToProcess,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    processEndOfDay(_, date) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/open_day/process_end_of_day', date)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    putProcessChangePromotion(_, { openDay, isEnd, isEndCapture }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(
            '/open_day/process_Change_Promotion_OperationDay',
            {},
            {
              params: {
                openDay,
                isEndCapture,
                isEnd,
              },
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    putProcessChangePromotionOperationDay(_, { openDay, isEnd, isEndCapture }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(
            '/open_day/process_Change_Promotion_OperationDay',
            {},
            {
              params: {
                openDay,
                isEndCapture,
                isEnd,
              },
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    dateByDate(_, date) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day/get_operation_date_by_date', {
            params: {
              date,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchOperationsDayPosition(
      _,
      {
        orderBy = null,
        perPage,
        currentPage,
        filterByCurrencyPairId,
        filterByPortfolioId,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/operation_day_position_record?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByCurrencyPairId: filterByCurrencyPairId,
              FilterByPortfolioId: filterByPortfolioId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchOperationsDayPositionById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/operation_day_position_record/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateOperationsDayPosition(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('operation_day_position_record', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getTermDates(context) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day/get_term_dates')
          .then(response => {
            resolve(response.data)
            context.state.terms = response.data
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchBrokers(
      _,
      {
        perPage, currentPage, filterByIsActive = null, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/broker?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: filterByIsActive,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
