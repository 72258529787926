import axios from 'axios'
import { getEnvVariable } from '@/helpers'
import extendAxiosInstance from './baseAxiosInstance'

const pricesAxiosInstance = axios.create({
  baseURL: getEnvVariable('VUE_APP_API_URL', process.env.VUE_APP_API_URL),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: getEnvVariable('TIME_ABORT_REQUEST', process.env.TIME_ABORT_REQUEST),
})

extendAxiosInstance(pricesAxiosInstance)

export default pricesAxiosInstance
