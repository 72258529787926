import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSettlementTypesByCurrency(context, { PayeeId, CurrencyId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/settlement-types/get_by_currency', {
            params: {
              PayeeId,
              CurrencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSettlementTypesByCurrencyProduct(_, {
      CurrencyISOCode, CurrencyISOCodeContra, CustomerCode, ProductCode, IsBuying, IsCanje, IsCCy, BankingArea,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/settlement_type/get_settlement_by_product_currency', {
            params: {
              CurrencyISOCode,
              CurrencyISOCodeContra,
              CustomerCode,
              ProductCode,
              IsBuying,
              IsCanje,
              IsCCy,
              BankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
