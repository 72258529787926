const getLocalProfile = state => {
  if (state.profile) return state.profile
  const profile = localStorage.getItem('Scisa.Security.Consoleuser:https://identity.scisa.com.mx:Framework')
  if (profile) {
    return JSON.parse(profile)
  }
  return {}
}

export default {
  namespaced: true,
  state: {
    PreferredLanguage: null,
    SCISA: null,
    azp: null,
    email: null,
    email_verified: null,
    name: null,
    oi_au_id: null,
    oi_tkn_id: null,
    sub: null,
  },
  getters: {
    profile(state) {
      return getLocalProfile(state)
    },
  },
  mutations: {
  },
  actions: {
  },
}
