import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    address: {
      addressBankId: {
        display: true,
        value: '',
        type: 'input',
        label: 'addressBankId',
      },
      bankSettlementTypeId: {
        display: true,
        value: '',
        type: 'input',
        label: 'bankSettlementTypeId',
      },
      addressBank: {
        display: true,
        value: '',
        type: 'input',
        label: 'addressBank',
      },
      version: {
        display: false,
        value: '',
        type: 'input',
      },
    },
  },
  getters: {
    getBankSettlement(state) {
      return state.address
    },
  },
  mutations: {},
  actions: {
    fetchAddressBanksById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/address_bank/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateAddressBanksById(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/address_bank/${id}`, {
            addressBankId: data.addressBankId,
            bankSettlementTypeId: data.bankSettlementTypeId,
            addressBank: data.addressBank,
            version: data.version,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createAddressBanksById(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/address_bank', {
            addressBankId: data.addressBankId,
            bankSettlementTypeId: data.bankSettlementTypeId,
            addressBank: data.addressBank,
            version: data.version,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteAddressBanksById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .delete(`/address_bank/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
