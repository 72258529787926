import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {
    webClient: {
      id: {
        display: false,
        value: '',
        type: '',
      },
      error: {
        display: false,
        value: '',
        type: '',
      },
      clientID: {
        display: false,
        value: '',
        type: '',
      },
      personName: {
        display: false,
        value: '',
        type: '',
      },
      emailTo: {
        display: false,
        value: '',
        type: '',
      },
      userName: {
        display: false,
        value: '',
        type: '',
      },
      webClientName: {
        display: false,
        value: '',
        type: '',
      },
      claveONMI: {
        display: false,
        value: '',
        type: '',
      },
      maxWebAmount: {
        display: false,
        value: '',
        type: '',
      },
      maxAmount: {
        display: false,
        value: '',
        type: '',
      },
      pwdNotificationEmailID: {
        display: false,
        value: '',
        type: '',
      },
      isWebClient: {
        display: false,
        value: '',
        type: '',
      },
      isCreateTxAllowed: {
        display: false,
        value: '',
        type: '',
      },
      isReadTxAllowed: {
        display: false,
        value: '',
        type: '',
      },
      isReadPayeeAllowed: {
        display: false,
        value: '',
        type: '',
      },
      isCreatePayeeAllowed: {
        display: false,
        value: '',
        type: '',
      },
      isReadCurrencyPairAllowed: {
        display: false,
        value: '',
        type: '',
      },
      isAuthorizeTxAllowed: {
        display: false,
        value: '',
        type: '',
      },
      contractCode: {
        display: false,
        value: '',
        type: '',
      },
      version: {
        display: false,
        value: '',
        type: '',
      },
    },
  },
  getters: {
    paymentTypes(state) {
      return state.webClient
    },
  },
  mutations: {},
  actions: {
    getWebClients(
      _,
      {
        perPage, currentPage, orderBy = null, filterByClientId, filterByIsActive, client, filteryByIsWebClient,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`web_client/clients?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: filterByIsActive,
              FilterByClientId: filterByClientId,
              FilteryByClientName: client,
              FilteryByIsWebClient: filteryByIsWebClient,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getWebClientsByIndex(
      _,
      {
        perPage, currentPage, orderBy = null, filterByClientId, filterByIsActive, client, filteryByIsWebClient,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`web_client/index?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: filterByIsActive,
              FilterByClientId: filterByClientId,
              FilteryByClientName: client,
              FilteryByIsWebClient: filteryByIsWebClient,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    searchWebClients(
      _,
      {
        name, isWeb,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('web_client/get_web_client', {
            params: {
              clientName: name,
              isWebClient: isWeb,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    resetPassword(_, { userName }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('web_client/reset_password', { params: { userName } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    resetLogins(_, { userName }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`web_client/restart_login_attempts?userName=${userName}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAssociated(_, { userName }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('web_client/get_associated_web_clients_by_user_web_name', {
            params: {
              userName,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateClient(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('web_client', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
