import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import BootrapVue from 'bootstrap-vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import money from 'v-money'
import VueCroppie from 'vue-croppie'
import i18n from '@/libs/i18n'
import '@/libs/base64'

import Vidle from 'v-idle'
import router from './router'
import App from './App.vue'
import store from './store'
import './filters'

import 'vue-resize/dist/vue-resize.css'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// Axios Mock Adapter
import '@/@fake-db/db'

// eslint-disable-next-line
import('croppie/croppie.css')

Vue.component('VueTypeaheadBootstrap', VueTypeaheadBootstrap)

// Composition API
Vue.use(VueCompositionAPI)
// Bootstrap Vue
Vue.use(BootrapVue)

Vue.use(Vidle)

Vue.use(VueCroppie)

// register directive v-money and component <money>
Vue.use(money, { precision: 4 })

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
