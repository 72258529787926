<template>
  <div
    ref="container"
    class="container"
  >
    <b-button
      ref="target"
      class="overlay bg-custom-dark"
      :class="{ animated: !isSwiping }"
      :style="{ left, opacity }"
      @click="$emit('clickEvent')"
    >
      <span class="h4">
        {{ name }}
      </span>
    </b-button>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useSwipe } from '@vueuse/core'

export default {
  props: {
    name: {
      type: String,
      default() {
        return 'NA'
      },
    },
    index: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  setup(props, { emit }) {
    const target = ref(null)
    const container = ref(null)
    const containerWidth = computed(() => container.value?.offsetWidth)
    const left = ref('0')
    const opacity = ref(1)

    function reset() {
      left.value = '0'
      opacity.value = 1
    }

    const { isSwiping, lengthX } = useSwipe(
      target,
      {
        onSwipe() {
          if (containerWidth.value) {
            if (lengthX.value > 0) {
              const length = Math.abs(lengthX.value)
              left.value = `-${length}px`
              opacity.value = 1.1 - length / containerWidth.value
            } else {
              left.value = '0'
              opacity.value = 1
            }
          }
        },
        onSwipeEnd() {
          if (lengthX.value > 0 && containerWidth.value && (Math.abs(lengthX.value) / containerWidth.value) >= 0.5) {
            left.value = '-100%'
            opacity.value = 0
            emit('swipeEvent')
          } else {
            left.value = '0'
            opacity.value = 1
          }
        },
      },
    )

    return {
      target,
      container,
      containerWidth,
      left,
      opacity,
      reset,
      isSwiping,
      lengthX,
    }
  },
}
</script>

<style scoped>
.container {
  border-radius: 10px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.overlay {
  border-radius: 10px;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay.animated {
  transition: all 0.2s ease-in-out;
}

.overlay > span {
  text-align: center;
  overflow: hidden;
  white-space: normal;
}

.dark-layout .bg-custom-dark span {
  color: #f88424 !important;
}
</style>
