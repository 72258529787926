import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    findTrader(_, { traderCode, traderName, isTrader = 1 }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/employees/find-trader', {
            params: {
              traderCode,
              isTrader,
              empleado: traderName,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getLoggedEmployee() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/employees/get_Logged_employee')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getEmployeesAuthorizations(_, { employeeId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/employees/get_authorizations/${employeeId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveEmployeesAuthorizedPortfolios(_, employeeData) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/employees/save_authorizations', employeeData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getEmployeesAuthorizationsPortfolios() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/employees/get_employee_authorized_portfolios')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getByEmployeeTypeHierarchy(_, { EmployeeTypeId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/employees/get_by_employee_type_hierarchy/${EmployeeTypeId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getEmployeesByHierarchy() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/employees/get_by_hierarchy')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getEmployees() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/employees/get_by_hierarchy')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
