import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getBranchOfficeByRegion(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/branch_office/get_by_region/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getBranchOffices(_, { orderBy = null, perPage, currentPage }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/branch_office?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: true,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getBranchOfficeById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/branch_office/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveNewBranchOffice(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/branch_office/', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateBranchOffice(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/branch_office/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTreasuryAccounts(
      _,
      {
        filterByIsActive, fiterByActiveTreasuryAccountsByCurrencyId, filterByIsTreasuryAccountsToAssociate, perPage,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/treasury_account', {
            params: {
              FilterByIsActive: filterByIsActive,
              FiterByActiveTreasuryAccountsByCurrencyId: fiterByActiveTreasuryAccountsByCurrencyId,
              FilterByIsTreasuryAccountsToAssociate: filterByIsTreasuryAccountsToAssociate,
              PerPage: perPage,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
