import Vue from 'vue'

const getBase64 = f => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(f)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

Vue.prototype.$getBase64 = getBase64
