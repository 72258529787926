import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

const swaggerValidator = require('swagger-object-validator')
/* swaggerValidator.Handler(####) can be a URL (swagger.json/yml) */
// This code fetches a Swagger file from the SigloNet API and stores it in sessionStorage.
// It is used to validate requests to the SigloNet API.
const config = {
  allowAdditionalProperties: true,
}

// eslint-disable-next-line consistent-return
const fetchSigloSwagger = () => new Promise((resolve, reject) => {
  const sigloSwagger = window.sessionStorage.getItem('sigloSwagger')
  if (sigloSwagger) {
    resolve(JSON.parse(sigloSwagger))
  } else {
    sigloNetAxiosInstance
      .get('/swagger/v1/swagger.json')
      .then(response => {
        window.sessionStorage.setItem('sigloSwagger', JSON.stringify(response.data))
        resolve(response.data)
      })
      .catch(error => reject(error))
  }
})

let validator
fetchSigloSwagger().then(sigloSwagger => {
  validator = new swaggerValidator.Handler(sigloSwagger, config)
}).catch(() => {})

const formatDates = model => {
  if (model.holidayDate) {
    // eslint-disable-next-line vue/no-mutating-props
    model.holidayDate = new Date(model.holidayDate).toISOString()
  }
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    validateForm(context, { model, spec }) {
      return new Promise((resolve, reject) => {
        /* eslint-disable-next-line */
        const specRef = validator.swaggerSpec['_rejectionHandler0'].components.schemas[spec]
        // FIXME: Added this to avoid a bug in swagger-object-validator
        // But is this the best way ???
        if (specRef.allOf) {
          delete specRef.allOf
        }
        formatDates(model)
        validator.validateModel(model, specRef, (err, result) => {
          if (result.errors.length > 0) {
            reject(result.errors)
          } else {
            resolve()
          }
        })
      })
    },
  },
}
