import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCountries() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('v1/countries')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getAuthorizedByClient(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/countries/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveNewCountry(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/countries', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCountryById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/countries/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateCountry(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/countries/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveNewCountryCurrency(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/countryCurrencies', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateCountryCurrency(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/countryCurrencies/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteCountryCurrency(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .delete(`/countryCurrencies/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
