import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getScheduledTasks() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/scheduled_task')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    restarScheduledTasks() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/scheduled_task/restar')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateScheduledTasks(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/scheduled_task/save', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
